'use strict';

$(document).ready(function() {

  $('.header__nav-toggle').click(function(){
    $('.header__nav').toggleClass('header__nav--open');
    $('.header').toggleClass('header--open');
  });

  $('.js-items-show').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    image: {
      verticalFit: true,
    },
    gallery: {
      enabled: true
    },
    zoom: {
      enabled: true,
      duration: 300, // don't foget to change the duration also in CSS
      opener: function(element) {
        return element.find('img');
      }
    }
  });

  $('.js-artifact-slider').slick({
    dots: true,
    dotsClass: 'artifact__dots',
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false
  });

  $('.js-artifact-slider-for').slick({
    dots: true,
    dotsClass: 'artifact__dots',
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false,
    asNavFor: '.js-artifact-slider-nav'
  });

  $('.js-artifact-slider-nav').slick({
    dots: true,
    dotsClass: 'artifact__dots',
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false,
    asNavFor: '.js-artifact-slider-for',
  });

  $('.js-box-slider').slick({
    dots: true,
    dotsClass: 'box-slider__dots',
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true
  });

  $('.box-slider__dots li:first-child button').text('До реставрации');
  $('.box-slider__dots li:nth-child(2) button').text('В процессе реставрации');
  $('.box-slider__dots li:nth-child(3) button').text('После реставрации');

  $('.js-compare-slide').twentytwenty();

  $('.js-slider-check').click( function(){
    $(this).toggleClass('artifact__check--after');

    if ($(this).is('.artifact__check--after')) {
      $('.artifact__slider--berofe').hide();
      $('.artifact__slider--after').show();
      $('.artifact__slider--after').slick('refresh');
    } else {
      $('.artifact__slider--berofe').show();
      $('.artifact__slider--after').hide();
    }
  });



  $('.js-btn-show-map').click(function(){
    $('.scheme__show').fadeIn(1000).addClass('scheme__show--open');
  });

  $('.js-btn-close-map').click(function(){
    $('.scheme__show').fadeOut(800).removeClass('scheme__show--open');
  });

  $('.scheme__point').click(function(){
    var point = $(this).attr('data-num');
    $('.scheme__point-wrap').removeClass('scheme__point-wrap--open')
    $(".scheme__point-wrap[data-point='" + point + "']").addClass('scheme__point-wrap--open');
  });

  $('.scheme__point-close').click(function(e){
    e.preventDefault();
    $(this).parents('.scheme__point-wrap').removeClass('scheme__point-wrap--open');
  });


});